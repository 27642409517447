import { postTry } from '@beelineloans/cx-library/dist/utils/fetch';
import { calculateEndpoint } from '@beelineloans/cx-library/src/utils/helpers';

const calculateBorrowingPower = async (data) => {
  const calculateResponse = await postTry(process.env.GATSBY_API_CALC_ENDPOINT, data);

  if (calculateResponse.status === 'ERROR') {
    const message = calculateResponse.message || '';
    return {
      status: 'VALIDATION',
      errors: message
    };
  }

  return calculateResponse;
};

const calculatePlans = async (data) => {
  const headers = { 'x-api-key': process.env.GATSBY_PLAN_API_KEY };

  const resp = await postTry(calculateEndpoint, data, headers);
  return resp;
};

const propertyTypeOptions = [
  { val: 0, text: 'Single-family' }, // 1 unit
  { val: 3, text: 'Multi-family' }, // 4 unit
  { val: 2, text: 'Condo' }, // Warrantable condo < 5 stories
  { val: 5, text: 'Townhouse' }, // Manufactured home
  { val: -1, text: 'Not sure' }
];
const occupancyOptions = [
  { val: 0, text: 'Primary Residence' },
  { val: 1, text: 'Second Home' },
  { val: 2, text: 'Investment Property' }
];
const optionTextToValue = (options, text) => {
  const option = options.find((p) => p.text === text);
  if (option) return option.val;
  return 2;
};

const toPlanQuery = (data) => ({
  zipCode: data.zipCode,
  firstTimeBuying: 'True',
  creditRange: data.creditScore,
  propertyType: optionTextToValue(propertyTypeOptions, data.dwellingType),
  occupancy: optionTextToValue(occupancyOptions, data.occupancyType),
  purchasePrice: data.finalMaxPurchase,
  downPayment: +(data.finalMaxPurchase - data.finalMaxLoanAmount).toFixed(2),
  loanTerm: data.loanTermInYear,
  loanType: 'Fixed',
  service: process.env.GATSBY_PLAN_CALCULATOR_SERVICE,
  purpose: 0
});

export {
  calculateBorrowingPower,
  toPlanQuery,
  calculatePlans
};
