import 'regenerator-runtime/runtime';
import React, { useState, useRef } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import Container from '@beelineloans/cx-library/dist/components/layout/Container';
import { H3, Span } from '@beelineloans/cx-library/dist/components/typography';
import { Header } from '@beelineloans/cx-library/dist/components/layout/headers';
import { NumberField, DropdownField, RadioField } from '@beelineloans/cx-library/dist/components/forms/fields';
import { Button } from '@beelineloans/cx-library/dist/components/buttons';
import { Theme } from '@beelineloans/cx-library/dist/theme/default';
import GlobalStyle from '@beelineloans/cx-library/dist/theme/global-styles';
import { calculateBorrowingPower, toPlanQuery, calculatePlans } from '../api';

const PageHeader = styled(Header)`
  div {
    height: 120px;
  }

  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    height: 150px;
  }
`;

const ContainerStyled = styled(Container)`
	.container-inner, .field-container div:first-child {
		display: grid;
		grid-template-columns: 100%;
		grid-template-areas: ".";
		grid-column-gap: 0px;
		grid-row-gap: 0;
		align-items: center;
		width: 100%;

		@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
			grid-template-columns: 50% 50%;
			grid-template-areas: ". .";
		}

    padding-bottom: 0px;
    @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
      padding-bottom: 0px;
    }
    @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
      padding-bottom: 0px;
    }
	}

  .field-container div:first-child {
    @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
			grid-template-columns: 40% 60%;
		}
  }

  :last-of-type {
    margin-bottom: 50px;
    .field-container {
      margin-bottom: 5px;
    }
    .field-container input {
      width: 50%;
      line-height: 20px;
    }
  }
`;

const Half = styled.div`
  border-top:       1px solid ${props => props.theme.colours.border.default};
  padding-top:      50px;
  align-self:       flex-start;

  ${props => props.noborder && css`
			border-top: 0px;
		`}

  @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
    margin:           0;
    &:nth-child(odd) {
      padding-right:  50px;
    }
  }

  div div label {
    color:  ${props => props.theme.colours.text.dark};
  }

  #zipCode {
    width: 50%;
  }

  #remainingLoanAmount, #estimatedPropertyValue {
    width: 80%;
  }

  :nth-of-type(2) {
    input {
      width: 80%;
    }
  }

  input, .custom-select {
    border-radius: 10px;
    border: 2px solid ${props => props.theme.colours.border.default} !important;
  }

  span:first-of-type {
    margin-right: 100px;
  }
  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    span:first-of-type {
      margin-right: 150px;
    }
  }

  button {
    border-radius: 5px;
    text-decoration: none;
  }
`;

const ButtonWithMargin = styled(Button)`
  margin-bottom:  50px;
`;

const EmptyGridItem = styled.div`
	display: none;

	@media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
		display: initial;
	}
`;

const Heading = styled(H3)`
	padding-bottom: 20px;
`;

const ResultTitle = styled(Span)`
  margin-right: ${props => (props.noHPadding ? 0 : 100)}px !important;
  ${props => props.error && css`
			color: ${props.theme.colours.error};
		`}
`;

const Result = styled(Span)`
`;

const loanPurposeOptions = [
  { val: 'Purchase', text: 'Purchase' },
  { val: 'Term Refi', text: 'Refi - Term' }
];
const dwellingTypeOptions = [
  { val: 'Single-family', text: 'Single family' },
  { val: 'Multi-family', text: 'Multi-family' },
  { val: 'Condo', text: 'Condo' },
  { val: 'Townhouse', text: 'Townhouse' },
  { val: 'Planned Unit Development', text: 'Planned Unit Development' },
  { val: 'Not sure', text: 'Not sure' }
];
const unitsNumberOptions = [
  { val: 1, text: '1' },
  { val: 2, text: '2' },
  { val: 3, text: '3' },
  { val: 4, text: '4 & 4+' }
];
const occupancyTypeOptions = [
  { val: 'Primary Residence', text: 'Primary Residence' },
  { val: 'Second Home', text: 'Second Home' },
  { val: 'Investment Property', text: 'Investment' }
];
const loanTermOptions = [
  { val: 15, text: '15 Yrs' },
  { val: 20, text: '20 Yrs' },
  { val: 25, text: '25 Yrs' },
  { val: 30, text: '30 Yrs' }
];
const toggleOptions = [
  { val: 'True', text: 'Yes' },
  { val: 'False', text: 'No' }
];

const toCurrency = (v) => (v ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v) : '');
const toPercentage = (v) => (v ? v.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 }) : '');
const toFeeDesc = (v) => {
  if (!Array.isArray(v)) {
    return v;
  }
  return v.reduce((acc, cur) => (cur.formattedAmount === '$0' ? acc : `${acc}\r${cur.itemName}\t${cur.formattedAmount}`), '');
};

const MaxBorrowingPowerCalculation = () => {
  const PageName = <Heading>Max Borrowing Power Estimator</Heading>;

  const defaultValudes = {
    loanTermInYear: loanTermOptions[loanTermOptions.length - 1].val,
    unitsNumber: unitsNumberOptions[0].val,
    dwellingType: dwellingTypeOptions[0].val,
    occupancyType: occupancyTypeOptions[0].val,
    loanPurpose: loanPurposeOptions[0].val,
    financedPropertiesCountIncludingPrincipalResidenceAndSubject: 0,
    financedPropertiesCountExcludingPrincipalResidenceOrSubjectTotalUPB: 0,
  };
  const [calculating, setCalculating] = useState(false);
  const [workingValues, setWorkingValues] = useState(defaultValudes);
  const [calculateValues, setCalculateValues] = useState({});
  const [settingValues, setSettingValues] = useState({});
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState('');
  const summaryRef = useRef(null);

  const scrollToSummary = () => {
    // eslint-disable-next-line no-unused-expressions
    summaryRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleInputChange = (field, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component

    const tryParseNumber = type === 'float' ? parseFloat(newValue)
      : (type === 'percentage' ? (newValue.endsWith('%') ? +(parseFloat(newValue) / 100).toFixed(4) : +(parseFloat(newValue)).toFixed(4)) : parseInt(newValue, 10));
    const updatedValue = {
      [field]: Number.isNaN(tryParseNumber) ? newValue : (tryParseNumber)
    };

    if (field === 'loanPurpose') setPlans([]);

    setWorkingValues((prev) => ({ ...prev, ...updatedValue }));
  };

  const handleSettingsChange = (field, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component

    const tryParseNumber = type === 'float' ? parseFloat(newValue)
      : (type === 'percentage' ? (newValue.endsWith('%') ? +(parseFloat(newValue) / 100).toFixed(4) : +(parseFloat(newValue)).toFixed(4)) : parseInt(newValue, 10));
    const updatedValue = {
      [field]: Number.isNaN(tryParseNumber) ? newValue : (tryParseNumber)
    };

    setSettingValues((prev) => ({ ...prev, ...updatedValue }));
  };

  const calculate = async () => {
    setCalculating(true);
    setCalculateValues({});
    setPlans([]);
    setError('');
    const result = await calculateBorrowingPower({
      ...workingValues,
      inputSettings: {
        ...settingValues
      }
    });
    if (result.status === 'SUCCESS') {
      if (workingValues.loanPurpose === 'Purchase' && result.chosenPlan && result.chosenPlan.finalMaxLoanAmount) {
        setCalculateValues({ maxLoanAmountThreshold: result.maxLoanAmountThreshold, ...result.chosenPlan });
        setSettingValues(result.settings);
        // feed loan amount etc to plan calculation to find plans
        const plansResp = await calculatePlans(toPlanQuery({ ...workingValues, ...result.chosenPlan }));
        const availablePlans = [];
        // check plans result
        if (plansResp && plansResp.programs) {
          ['balanced', 'lowestMonthly', 'lowestUpfront'].map(p => {
            const plan = plansResp.programs[p];
            if (plan) availablePlans.push({ planType: p, ...plan });
            return null;
          });
        }
        setPlans(availablePlans);
      } else if (workingValues.loanPurpose === 'Term Refi' && result.plans && result.plans.length > 0) {
        setPlans(result.plans);
      } else {
        setError('No Available Plan');
      }
      scrollToSummary();
    } else if (Array.isArray(result.errors)) {
      setError(result.errors[0].message);
    } else {
      setError('ERROR');
    }
    setCalculating(false);
  };

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <PageHeader headerChildrenRight={PageName} hideNav />
      <ContainerStyled noVPadding>
        <Half>
          <Heading>Subject Property</Heading>
          <DropdownField
            id="loanPurpose"
            label="Application Type"
            options={loanPurposeOptions}
            onInputChange={handleInputChange('loanPurpose')}
            selectedValue={workingValues.loanPurpose}
            border={false}
            rounded
          />
          <DropdownField
            id="occupancyType"
            label="Residence Type"
            options={occupancyTypeOptions}
            onInputChange={handleInputChange('occupancyType')}
            selectedValue={workingValues.occupancyType}
            border={false}
            rounded
          />
          <DropdownField
            id="dwellingType"
            label="Property Type"
            options={dwellingTypeOptions}
            onInputChange={handleInputChange('dwellingType')}
            selectedValue={workingValues.dwellingType}
            border={false}
            rounded
          />
          <DropdownField
            id="unitsNumber"
            label="Number of Units"
            options={unitsNumberOptions}
            onInputChange={handleInputChange('unitsNumber')}
            selectedValue={workingValues.unitsNumber}
            border={false}
            rounded
          />
          <NumberField
            id="zipCode"
            label="ZIP Code"
            onInputChange={handleInputChange('zipCode')}
            border={false}
            rounded
            placeholder=""
          />
          {
            workingValues.loanPurpose === 'Term Refi' && (
              <>
                <NumberField
                  id="remainingLoanAmount"
                  label="Remaining Loan Amount"
                  onInputChange={handleInputChange('remainingLoanAmount', 'float')}
                  border={false}
                  rounded
                  formatToFloat
                  placeholder="0"
                />
                <NumberField
                  id="estimatedPropertyValue"
                  label="Estimated Property Value"
                  onInputChange={handleInputChange('estimatedPropertyValue', 'float')}
                  border={false}
                  rounded
                  formatToFloat
                  placeholder="0"
                />
              </>
            )
          }
        </Half>
        <Half>
          <Heading>Borrower Financial</Heading>
          <NumberField
            id="creditScore"
            label="Credit Score"
            onInputChange={handleInputChange('creditScore')}
            border={false}
            rounded
            placeholder=""
          />
          <NumberField
            id="monthlyIncome"
            label="Monthly Income"
            onInputChange={handleInputChange('monthlyIncome', 'float')}
            border={false}
            rounded
            formatToFloat
            placeholder="0"
          />
          <NumberField
            id="monthlyLiabilities"
            label="Monthly Liabilities"
            onInputChange={handleInputChange('monthlyLiabilities', 'float')}
            border={false}
            rounded
            formatToFloat
            placeholder="0"
          />
          {
            workingValues.loanPurpose === 'Purchase' && (
              <>
                <NumberField
                  id="liquidAssets"
                  label="Total Liquid Assets"
                  onInputChange={handleInputChange('liquidAssets', 'float')}
                  border={false}
                  rounded
                  formatToFloat
                  placeholder="0"
                />
                <NumberField
                  id="maxDownPayment"
                  label="Down Payment"
                  onInputChange={handleInputChange('maxDownPayment', 'float')}
                  border={false}
                  rounded
                  formatToFloat
                  placeholder="0"
                />
                <DropdownField
                  id="loanTermInYear"
                  label="Loan Term"
                  options={loanTermOptions}
                  onInputChange={handleInputChange('loanTermInYear')}
                  selectedValue={workingValues.loanTermInYear}
                  border={false}
                  rounded
                />
              </>
            )
          }
          {
            workingValues.loanPurpose === 'Term Refi' && (
              <>
                <NumberField
                  id="loanTermInYear"
                  label="Remaining Loan Term"
                  onInputChange={handleInputChange('loanTermInYear')}
                  border={false}
                  rounded
                  placeholder="Yrs"
                />
              </>
            )
          }
        </Half>
      </ContainerStyled>
      <ContainerStyled noVPadding>
        <Half>
          <Heading>Realestate Owned</Heading>
          <NumberField
            id="financedPropertiesCountIncludingPrincipalResidenceAndSubject"
            label="Count of Properties With Mortgage. (inc. Principal Residence)"
            onInputChange={handleInputChange('financedPropertiesCountIncludingPrincipalResidenceAndSubject')}
            border={false}
            rounded
            placeholder="0"
          />
          <NumberField
            id="financedPropertiesCountExcludingPrincipalResidenceOrSubjectTotalUPB"
            label="Total Unpaid Balance Of All Mortgages (exc. Principal Residence)"
            onInputChange={handleInputChange('financedPropertiesCountExcludingPrincipalResidenceOrSubjectTotalUPB')}
            border={false}
            rounded
            formatValue
            placeholder="0"
          />
        </Half>
        <Half>
          <EmptyGridItem />
        </Half>
      </ContainerStyled>
      <ContainerStyled noVPadding>
        <Half>
          <ButtonWithMargin selected onClick={calculate} loading={calculating}>CALCULATE BORROWING POWER</ButtonWithMargin>
          <br />
          {
            workingValues.loanPurpose === 'Purchase' && (
              <>
                <ResultTitle>Est. Max Loan Amount</ResultTitle><Result>{toCurrency(calculateValues.finalMaxLoanAmount)}</Result><br />
                <ResultTitle>Est. Max Purchase Price</ResultTitle><Result>{toCurrency(calculateValues.finalMaxPurchase)}</Result><br />
                <ResultTitle>DTI Used</ResultTitle><Result>{toPercentage(calculateValues.finalDTI)}</Result><br />
                <ResultTitle>LTV Used</ResultTitle><Result>{toPercentage(calculateValues.finalLTV)}</Result><br />
                <ResultTitle>Loan Amount Threshold</ResultTitle><Result>{toCurrency(calculateValues.maxLoanAmountThreshold)}</Result><br />
                <ResultTitle>Reserve Months</ResultTitle><Result>{calculateValues.numOfReserveInMonth}</Result><br />
                <ResultTitle>Reserve Amount</ResultTitle><Result>{toCurrency(calculateValues.reserveAmount)}</Result><br />
                <ResultTitle>Available Plans</ResultTitle><br />
                {plans.map((plan) => (
                  <>
                    <Result>{`${plan.planType.toUpperCase()}`}</Result><br />
                    <Result>{`${plan.rate}%/${plan.apr}% APR`}</Result><br />
                    <Result>{`${plan.monthlyPayment} Per month`}</Result><br />
                    <Result>Included Fees</Result><br />
                    {plan.fees && Object.keys(plan.fees).map((key) => (
                      plan.fees[key] !== 0 && plan.fees[key] !== '$0' && (
                        <>
                          <Result>{`${key}\t${toFeeDesc(plan.fees[key])}`}</Result><br />
                        </>
                      )
                    ))}
                    <br />
                  </>
                ))}
                <ResultTitle noHPadding error ref={summaryRef}>{error}</ResultTitle>
              </>
            )
          }
          {
            workingValues.loanPurpose === 'Term Refi' && (
              <>
                <ResultTitle>Available Plans</ResultTitle><br />
                {plans.map((plan) => (
                  <>
                    <Result>{`Loan Amount ${toCurrency(plan.remainingLoanAmount)}`}</Result><br />
                    <Result>{`${plan.loanTermInYear} Yrs`}</Result><br />
                    <Result>{`rate ${toPercentage(plan.finalRate)}`}</Result><br />
                    <Result>{`Monthly payment ${toCurrency(plan.finalPMT)}`}</Result><br />
                    <br />
                  </>
                ))}
                <ResultTitle noHPadding error ref={summaryRef}>{error}</ResultTitle>
              </>
            )
          }
        </Half>
        <Half>
          <EmptyGridItem />
          {typeof settingValues.interestRate !== 'undefined' && (
            <>
              <NumberField
                id="interestRate"
                label="Interest Rate"
                value={settingValues.interestRate}
                onInputChange={handleSettingsChange('interestRate', 'percentage')}
                border={false}
                rounded
                formatToPercentage
              />
              <NumberField
                id="taxRate"
                label="Tax Rate"
                value={settingValues.taxRate}
                onInputChange={handleSettingsChange('taxRate', 'percentage')}
                border={false}
                rounded
                formatToPercentage
              />
              <NumberField
                id="insuranceRate"
                label="Insurance Rate"
                value={settingValues.insuranceRate}
                onInputChange={handleSettingsChange('insuranceRate', 'percentage')}
                border={false}
                rounded
                formatToPercentage
              />
              <NumberField
                id="PMIRate"
                label="PMI Rate"
                value={settingValues.PMIRate}
                onInputChange={handleSettingsChange('PMIRate', 'percentage')}
                border={false}
                rounded
                formatToPercentage
              />
              <NumberField
                id="HOAAmount"
                label="HOAAmount"
                value={settingValues.HOAAmount}
                onInputChange={handleSettingsChange('HOAAmount', 'float')}
                border={false}
                rounded
                formatToFloat
              />
              <NumberField
                id="closingCostsRate"
                label="Closing Cost Rate"
                value={settingValues.closingCostsRate}
                onInputChange={handleSettingsChange('closingCostsRate', 'percentage')}
                border={false}
                rounded
                formatToPercentage
              />
              <>
                <RadioField
                  id="includeReserves"
                  label="Include Reserves"
                  onInputChange={handleSettingsChange('includeReserves', 'option')}
                  options={toggleOptions}
                  selectedValue={settingValues.includeReserves ? 'True' : 'False'}
                  border={false}
                />
              </>
              <>
                <RadioField
                  id="includeClosingCost"
                  label="Include Closing Cost"
                  onInputChange={handleSettingsChange('includeClosingCost', 'option')}
                  options={toggleOptions}
                  selectedValue={settingValues.includeClosingCost ? 'True' : 'False'}
                  border={false}
                />
              </>
            </>
          )}
        </Half>
      </ContainerStyled>
    </ThemeProvider>
  );
};

export default MaxBorrowingPowerCalculation;
